import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles/index"
import { Container, Grid } from "@material-ui/core/index"
import Layout from "../components/layout"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import LineTitle from "../components/LineTitle"
import TreatmentCard from "../components/Cards/TreatmentsCard"
import Images from "../styles/Images"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  bannerRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "60px",
    },
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
    },
  },
  tooLongtitle: {
    ["@media (max-width:600px)"]: {
      fontSize: "2rem",
    },
  },
  tooLongSubheader: {
    color: "white",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    maxWidth: "550px",
    ["@media (max-width:600px)"]: {
      fontSize: "1.3rem",
    },
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  container: {
    backgroundColor: "transparent",
  },
  treatmentsBanner: {
    paddingTop: "85px",
    justifyContent: "flex-end",
    ["@media (max-width:600px)"]: {
      display: "none",
    },
  },
  docMobile: {
    ["@media (min-width:600px)"]: {
      display: "none",
    },
  },
  pageTitle: {
    paddingTop: "80px",
    color: "#565A56",
  },
  treatmentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    maxWidth: "1800px",
    alignItems: "center",
    paddingTop: "80px",
    paddingBottom: "120px",
    backgroundColor: "#202020",
  },
  listItemz: {
    paddingLeft: "14px",
    lineHeight: 1.3,
    listStyleType: "none",
    "&:before": {
      color: "#909690",
      float: "left",
      margin: "0 0 0 -1em",
      width: "1em",
      content: "'-'",
    },
  },
  cardInnerContainer: {
    paddingLeft: "80px",
    paddingRight: "80px",
  },
}))

const Treatments = ({ intl, data, location }) => {
  const banner = data.bannerImage.childImageSharp.fluid
  const skin = data.skin.childImageSharp.fluid
  const eye = data.eye.childImageSharp.fluid
  const lip = data.lip.childImageSharp.fluid
  const forehead = data.forehead.childImageSharp.fluid
  const nek = data.nek.childImageSharp.fluid
  const totalface = data.totalface.childImageSharp.fluid
  const rhino = data.rhino.childImageSharp.fluid
  const ear = data.ear.childImageSharp.fluid
  const hand = data.hand.childImageSharp.fluid
  const correct = data.correct.childImageSharp.fluid
  const classes = useStyles({ banner })
  // Translate function made shorter..
  const t = (id) => intl.formatMessage({ id })

  const createItemsForCard = (name, len) =>
    Array(len)
      .fill()
      .map((v, i) => (
        <li className={classes.listItemz}>{t(`treatments.${name}${i}`)}</li>
      ))

  const treatmentsCardData = [
    {
      title: t("treatments.totalFaceItemTitle"),
      listItems: createItemsForCard("totalFaceItem", 4),
      img: skin,
      iconName: "fillersStripe",
      text: t("treatments.totalFaceItemText"),
      backgroundColor: "#828382",
    },
    {
      title: t("treatments.eyeItemTitle"),
      listItems: createItemsForCard("eyeItem", 2),
      img: eye,
      iconName: "fillersStripe",
      text: t("treatments.skinItemText"),
      backgroundColor: "#5B5B5B",
    },
    {
      title: t("treatments.lipsItemTitle"),
      listItems: createItemsForCard("lipsItem", 2),
      img: lip,
      iconName: "fillersStripe",
      text: t("treatments.lipsItemText"),
      backgroundColor: "#828382",
    },
    {
      title: t("treatments.foreheadItemTitle"),
      listItems: createItemsForCard("foreheadItem", 2),
      img: forehead,
      iconName: "fillersStripe",
      text: t("treatments.foreheadItemText"),
      backgroundColor: "#666B65",
    },
    {
      title: t("treatments.jawItemTitle"),
      listItems: createItemsForCard("jawItem", 4),
      img: nek,
      iconName: "fillersStripe",
      text: t("treatments.jawItemText"),
      backgroundColor: "#5B5B5B",
    },
    {
      title: t("treatments.skinItemTitle"),
      listItems: createItemsForCard("skinItem", 3),
      img: totalface,
      iconName: "fillersStripe",
      text: t("treatments.skinItemText"),
      backgroundColor: "#666B65",
    },
    {
      title: t("treatments.rhinoItemTitle"),
      listItems: createItemsForCard("rhinoItem", 3),
      img: rhino,
      iconName: "fillersStripe",
      text: t("treatments.rhinoItemText"),
      backgroundColor: "#666B65",
      // imageHeight: "70%",
    },
    {
      title: t("treatments.earItemTitle"),
      listItems: createItemsForCard("earItem", 3),
      img: ear,
      iconName: "fillersStripe",
      text: t("treatments.earItemText"),
      backgroundColor: "#5B5B5B",
    },
    {
      title: t("treatments.handItemTitle"),
      listItems: createItemsForCard("handItem", 3),
      img: hand,
      iconName: "fillersStripe",
      text: t("treatments.handItemText"),
      backgroundColor: "#828382",
      imageHeight: "77%",
    },
    {
      title: t("treatments.correctionItemTitle"),
      listItems: createItemsForCard("correctionItem", 3),
      img: correct,
      iconName: "fillersStripe",
      text: t("treatments.correctionItemText"),
      backgroundColor: "#666B65",
      // imageHeight: "58%",
      alignImage: "center",
    },
  ]

  // console.log("INTEL TÄÄLÄ", intl.locale)
  // console.log("reach ROUTER", location.pathname)
  return (
    <Layout>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "treatments.SEOtitle" })}
        description={intl.formatMessage({ id: "treatments.SEO" })}
        path={location.pathname}
      />
      <MainToolbar />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <BackgroundImage
          fluid={banner}
          className={classes.backgroundImg}
          backgroundColor={`#333`}
        >
          <Grid container spacing={0} className={classes.bannerGrid}>
            <Grid item xs={12} sm={6}>
              <Container>
                <LineTitle
                  header={t("treatments.header")}
                  subheader={t("treatments.subheader")}
                  className={classes.pageTitle}
                  titleStyle={classes.tooLongtitle}
                  subHeaderStyle={classes.tooLongSubheader}
                />
              </Container>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.bannerRight}></Grid>
          </Grid>
        </BackgroundImage>
        <Container maxWidth={"xl"} className={classes.cardContainer}>
          <Container maxWidth={"xl"} className={classes.treatmentContainer}>
            {treatmentsCardData.map((card) => (
              <div className={classes.cardInnerContainer}>
                <TreatmentCard
                  title={card.title}
                  listItems={card.listItems}
                  cardText={card.text}
                  iconName={card.iconName}
                  img={card.img}
                  key={card.title}
                  backgroundColor={card.backgroundColor}
                  imageHeight={card.imageHeight}
                  alignImage={card.alignImage}
                ></TreatmentCard>
              </div>
            ))}
          </Container>
        </Container>
      </Container>
    </Layout>
  )
}

export default injectIntl(Treatments)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "groupTreatmentsFilterB20.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    skin: file(relativePath: { eq: "Skin2.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    eye: file(relativePath: { eq: "Eye2.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    lip: file(relativePath: { eq: "Lips.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    forehead: file(relativePath: { eq: "Forehead.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nek: file(relativePath: { eq: "Nek3.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    totalface: file(relativePath: { eq: "TotalFace5.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rhino: file(relativePath: { eq: "nose.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ear: file(relativePath: { eq: "Ears2.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hand: file(relativePath: { eq: "Hands.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    correct: file(relativePath: { eq: "correct5.png" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
