import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import fillersStripe from "../../images/fillersStripe.png"
import faceLiftStripe from "../../images/faceLiftStripe.png"
import noseStripe from "../../images/noseStripe.png"
import Grid from "@material-ui/core/Grid"
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
  card: {
    margin: "4em 0em",
    maxWidth: "500px",
    minWidth: "500px",
    maxHeight: "284px",
    minHeight: "280px",
    borderRadius: "20px",
    display: "flex",
    ["@media (max-width:600px)"]: {
      maxWidth: "375px",
      minWidth: "375px",
    },
  },
  gridContainer: {
    position: "relative",
    overflow: "hidden",
  },
  icon: {
    position: "absolute",
    backgroundPosition: "0 0",
    backgroundRepeat: "no-repeat",
    width: "176px",
    height: "176px",
  },
  leftContent: {
    position: "relative",
    paddingRight: "8px",
  },
  title: {
    color: "white",
    textTransform: "uppercase",
    paddingTop: "37px",
    paddingLeft: "37px",
    paddingBottom: "20px",
    marginRight: "-20px",
    ["@media (max-width:600px)"]: {
      fontSize: "20px",
    },
  },
  listText: {
    paddingTop: "0px",
    fontSize: "16px",
    color: "white",
    marginRight: "-10px",
    ["@media (max-width:600px)"]: {
      fontSize: "14px",
    },
  },
  listContainer: {
    minHeight: "76px",
    paddingBottom: "0px",
  },
  unorderedList: {
    marginBlockStart: "0px",
    marginRight: "0px",
  },
  cardText: {
    paddingLeft: "53px",
    // paddingRight: "4px",
    color: "white",
    marginRight: "-10px",
    lineHeight: "1.3",
    ["@media (max-width:600px)"]: {
      fontSize: "14px",
    },
  },
  fillersStripe: {
    backgroundImage: `url(${fillersStripe})`,
  },
  faceLiftStripe: {
    backgroundImage: `url(${faceLiftStripe})`,
  },
  noseStripe: {
    backgroundImage: `url(${noseStripe})`,
  },
  imageItem: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "visible",
    position: "relative",
    right: "0px",
    top: "0px",
    zIndex: "99!important",
    backgroundSize: "contain",
    [theme.breakpoints.down("md")]: {
      display: "flex",
    },
  },
  image: {
    backgroundSize: "contain",
    minWidth: "100%",
    height: "100%",
    width: "auto",
    position: "absolute",
    overflow: "visible",
    ["@media (max-width:600px)"]: {
      height: "100%",
      width: "92%",
    },
  },
}))

const TreatmentsCard = ({
  img,
  iconName,
  title,
  listItems,
  cardText,
  backgroundColor,
  imageHeight,
  alignImage,
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.card} style={{ background: backgroundColor }}>
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          xs={8}
          sm={8}
          md={7}
          lg={7}
          xl={7}
          className={classes.leftContent}
        >
          <Typography component="h3" variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography component="h5" variant="h5" className={classes.listText}>
            <div className={classes.listContainer}>
              <ul className={classes.unorderedList}>{listItems}</ul>
            </div>
          </Typography>
          {cardText === "treatments.correctionItemText" ? null : (
            <Typography className={classes.cardText}>{cardText}</Typography>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          md={5}
          lg={5}
          xl={5}
          className={classes.imageItem}
        >
          <Img
            fluid={img}
            style={{ height: imageHeight }}
            className={classes.image}
            alt={title}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default TreatmentsCard
